import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("sys/role/permission/list", { params });
}

export function Detail(params) {
  // console.log(parmas);
  return request.get("sys/role/permission/" + params);
}

export function Add(params) {
  return request.post("sys/role/permission", params);
}

export function Update(params) {
  return request.put("sys/role/permission", params);
}

export function Delete(data) {
  return request.delete("sys/role/permission", { data });
}
