<template>
  <div>
    <div>
      <div class="searchArea">
        <!-- <a-range-picker v-model:value="inputs.patroltime" :placeholder="['应巡开始时间','应巡结束时间']" :locale="locale" /> -->
        <a-input v-model:value="inputs.name" placeholder="名称" />
        <a-button type="primary" shape="circle" @click="search('')">
          <template #icon>
            <SearchOutlined />
          </template>
        </a-button>
        <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
        <a-button style="margin-left: 10px" type="primary" @click="visible = true">
          <template #icon>
            <PlusOutlined />
          </template>
          新增角色
        </a-button>
      </div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="data" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered rowKey="id" :pagination="{ pageSize ,total,current}" @change="search">

        <template #operation="{ record }">
          <div class="flex_sa">

            <div class="editable-row-operations flex_sa">
              <span v-if="editableData[record.id]">
                <a @click="save(record.id)">保存</a>
              </span>
              <span v-if="editableData[record.id]">
                <a @click="cancel(record.id)">取消</a>
              </span>
              <span v-else>
                <a @click="edit(record.id)">编辑</a>
              </span>
            </div>
            <div class="editable-row-operations">
              <span>
                <a @click="linkPermission(record)">权限配置</a>
              </span>
            </div>
            <div class="editable-row-operations">
              <span>
                <a-popconfirm title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </div>

          </div>

        </template>
        <template v-for="col in ['name','description','source']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" style="margin: -5px 0" />
            <template v-else>{{ text }}</template>
          </div>
        </template>
      </a-table>

    </div>

    <!-- 新增 -->
    <addRole v-model="visible" />
    <!-- 关联权限 -->
    <linkPermission v-model="visible2" :record="selectedRow" />

  </div>
</template>


<style scoped>
.online {
  background: #40a9ff;
  border-color: #40a9ff;
}
.offline {
  background: #979596;
  border-color: #979596;
}

:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Search, Update, Delete } from "/src/api/role.js";
import addRole from '/src/components/role/addRole.vue';
import linkPermission from '/src/components/role/linkPermission.vue';


export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    locale,
    addRole,
    linkPermission
  },
  name: "角色管理",
  data() {
    return {
      modalBtn: "",
      modalIndex: "0",
      pageSize: 12,
      visible: false,
      visible2: false,
      active: undefined,
      id: undefined,
      total: 0,
      current: 1,
      inputs: {
        name: undefined,
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      columns: [
        {
          title: "名称",
          key: "name",
          dataIndex: "name",
          width: "50%",
          slots: {
            customRender: "name"
          }
        },
        // {
        //   title: "首字母",
        //   key: "nameInitial",
        //   dataIndex: "nameInitial",
        //   width: "25%",
        //   slots: {
        //     customRender: "nameInitial"
        //   }
        // },
        {
          title: "描述",
          key: "description",
          dataIndex: "description",
          ellipsis: true,
          width: "25%",
          slots: {
            customRender: "description"
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "25%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRows = selectedRows;
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        }
      },
      editableData: reactive({}),
      selectedRows: {}
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    this.search("");
  },
  activated() {
    if (localStorage.getItem("refrashProject") == "true") {
      // this.search(1);
    }
    localStorage.setItem("refrashProject", false);
  },
  methods: {
    // 获取处方详情

    // 打开编辑处方

    resetForm() {
      this.inputs = {
        name: undefined,
      }
    },
    linkPermission(record) {
      this.visible2 = true
      this.selectedRow = { ...record }
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      if (res.errCode == 0) {
        this.data = this.data.filter(function (item) {
          return item.id !== id;
        });
      } else {
        this.$message.error("删除失败", function () { });
      }

    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async save(id) {
      let _data = this.data;

      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        // console.log("未修改")
        delete this.editableData[id];
        return;
      }
      let res = await Update(this.editableData[id])
      if (res.errCode == 0) {
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        // console.log(JSON.stringify(this.editableData[id]))
        // console.log(this.editableData[id])
        delete this.editableData[id];
      }
    },
    cancel(id) {
      delete this.editableData[id];
    },
    tabsName(tabName) {
      this.$store.commit("changePage", { name: tabName.name, query: tabName.query });
    },
    async search(pagination) {
      let _this = this;
      this.editableData = {}
      let params = {
        name: this.inputs.name,
        pageSize: this.pageSize,
      };
      if (pagination == "") {
        params.current = 1
      } else {
        params.current = pagination.current
        this.current = pagination.current;
      }
      let res = await Search(params)
      if (res.errCode == 0) {
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.total = res.data.total;
        this.data = res.data;
      } else {
        this.$message.error("查询失败", function () { });
      }
      // this.data = res
    },
  }
};
</script>