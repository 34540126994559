<template>
  <a-select v-model:value="value" mode="multiple" @popupScroll="popupScroll" :filterOption="filter" showSearch style="width: 201px;margin-right:10px">
    <a-select-option v-for="item in $store.state.permissionList" :value="item.id">{{ item.name }}</a-select-option>
  </a-select>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  created() {
  },
  mounted() {
    // console.log(this.$store.state.medicineList)
    // console.log(this.$store.state.medicineList.filter(item => item.id == 2416))
  },
  activated() {

  },
  emits: ['update:modelValue'],
  //获取父元素传值
  props: {
    modelValue: {
      type: Array,
      default: []
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    filter(input, option) {
      if (option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >=
        0) {
        console.log(option);
      }

      return (
        option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >=
        0
      );
    }
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}

.head span {
  font-weight: bold;
  margin-right: 10px;
}
</style>