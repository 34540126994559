<template>
  <div>
    <a-modal v-model:visible="visible" title="新增权限" @ok="handleOk" okText="新增" cancelText="取消" :afterClose="closeModal" width="30vw">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="名称">
          <a-input v-model:value="data.name" />
        </a-form-item>
        <a-form-item label="描述">
          <a-input v-model:value="data.description" />
        </a-form-item>
      </a-form>

    </a-modal>
  </div>
</template>

<script>
import { reactive } from "vue";
import { Add } from "/src/api/permission.js";
import medicineSelector from '/src/components/selector/medicineSelector.vue';
export default {
  components: {
    medicineSelector
  },
  data() {
    return {
      visible: this.modelValue,

      medicine: "",
      data: {
        name: undefined,
        description: undefined,
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {

  },
  methods: {

    async handleOk() {
      let _this = this;

      let res = await Add(this.data)
      if (res.errCode == 0) {
        _this.$message.success("新建成功", function () { });
        _this.visible = false
        this.data = {
          name: undefined,
          description: undefined,
        }
        this.$store.dispatch('getPermissionList')
      }
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    }
    //初始化data所有数据，并关闭modal
  },
  watch: {
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
</style>
