<template>
  <div>
    <a-modal v-model:visible="visible" title="关联权限" @ok="handleOk" okText="确认" cancelText="取消" :afterClose="closeModal" width="55vw"  :cancelButtonProps="{ style: { display: 'none' }}">
      <div class="head">
        <span>名称</span>
        <permissionTagsSelector v-model="permissionIdList" />
        <a-button @click="addToTable()">添加</a-button>
      </div>
      <a-table class="ant-table-striped" :columns="columns" :data-source="data" rowKey="id" :pagination="false" bordered>
        <template #title>
          <div class="thead">关联权限</div>
        </template>
        <template #operation="{ record }">
          <div class="flex_sa">
            <div class="editable-row-operations">
              <span>
                <a-popconfirm v-if="data.length" title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </div>
          </div>
        </template>
      </a-table>
      <div style="text-align: center;margin-top: 20px;">
        <a-button type="primary" @click="visible2=true">新增权限</a-button>
      </div>
    </a-modal>
  </div>
  <!-- <addPrescription v-model="visible2" @getData="callbackData" /> -->
  <addPermission v-model="visible2" />
</template>

<script>
import { reactive } from "vue";
import { Add, Search, Delete } from "/src/api/rolePermission.js";
import permissionTagsSelector from '/src/components/selector/permissionTagsSelector.vue';
import addPermission from '/src/components/permission/addPermission.vue';
export default {
  components: {
    permissionTagsSelector,
    addPermission
  },
  data() {
    return {
      visible: this.modelValue,
      visible2: false,
      reload: false,
      permissionIdList: [],
      selectedRows: {},
      columns: [
        {
          title: "名称",
          key: "permissionName",
          dataIndex: "permissionName",
          width: "75%",
          slots: {
            customRender: "permissionName"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "25%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: {}
    },
  },
  computed: {
  },
  methods: {
    filter() {
      return true
    },
    async detail() {
      
      let res = await Search({ roleId: this.record.id })
      console.log(res);
      // console.log(res)
      if (res.errCode == 0) {
        this.data = res.data
      } else {
        this.$message.error("详情查询失败", function () { });
      }
    },
    async handleOk() {
      let _this = this;
      let res = await Add({ roleId: this.record.id, permissionId: this.permissionId })
      if (res.errCode == 0) {
        _this.$message.success("关联成功", function () { });
        _this.visible = false
        // _this.reload = true
        this.permissionIdList = []
      }
    },
    async addToTable() {
      console.log(this.permissionIdList);
      let arr = []
      this.permissionIdList.forEach(element => {
        arr.push(this.$store.state.permissionList.find(item => item.id == element))
      });
      console.log(arr);
      //   this.data.push(...arr)
      for (let index = 0; index < arr.length; index++) {
        let res = await Add({roleId:this.record.id,roleName:this.record.name,permissionId:arr[index].id,permissionName:arr[index].name})
        if (res.errCode != 0) {
          this.$message.error("关联失败", function () { });
          return
        }
      }
      this.permissionIdList=[]
      this.detail()

    },
    async onDelete(id) {
      let res = await Delete({ id })
      if (res.errCode == 0) {
        this.data = this.data.filter(function (item) {
          return item.id !== id;
        });
      }


    },
    closeModal() {
      this.$emit('update:modelValue', false);
      //   if (this.reload) {
      //     this.$emit("reload")
      //   }
    },

  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
</style>
